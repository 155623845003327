<template>
  <div>
    <search-agent-monthly-bill @submit="submitSearch" />

    <div class="simiot-table-operation-buttons">
      <a-button type="primary" @click="exportData">导出</a-button>
    </div>

    <a-table
      size="middle"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      row-key="id"
    >
      <div slot="month" slot-scope="month, record">
        <router-link :to="{ name: 'agent_monthly_bill_info_commission', params: { agent_monthly_bill_id: record.id }}">
          {{ month }}
        </router-link>
      </div>

      <span slot="stopped_fee_title">
        <a-tooltip>
          <template slot="title">
            未出账时停机保号订单金额不计入月订单结算金额
          </template>
          停机保号订单金额(元)
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>

      <span slot="card_used_fee_title">
        <a-tooltip>
          <template slot="title">
            未出账时流量使用详情订单金额不计入月订单结算金额
          </template>
          流量使用详情订单金额(元)
          <a-icon type="question-circle" />
        </a-tooltip>
      </span>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination/index'
import SearchAgentMonthlyBill from '@/views/agent_monthly_bills_commission/Search'
import { findAgentMonthlyBills, exportAgentMonthlyBills } from '@/api/agent_monthly_bill'
import { formatCurrency } from '@/utils/filter'
import { exportExcel } from '@/api/excel'

export default {
  name: 'AgentMonthlyBillList',
  components: {
    SearchAgentMonthlyBill,
    Pagination
  },
  data() {
    return {
      data: [],
      booking_type: 1,
      loading: true,
      pagination: {
        total_count: 0
      },
      query: {
      }
    }
  },
  computed: {
    agentId() {
      return parseInt(this.$route.params.id)
    },

    columns() {
      return [
        {
          title: '订单月份',
          dataIndex: 'month',
          width: 100,
          fixed: 'left',
          scopedSlots: { customRender: 'month' }
        },
        {
          title: '订单状态',
          width: 100,
          fixed: 'left',
          dataIndex: 'status'
        },
        {
          title: '激活订单金额(元)',
          dataIndex: 'actived_fee',
          customRender: formatCurrency,
          width: 150
        },
        {
          title: '续期订单金额(元)',
          dataIndex: 'renewed_fee',
          customRender: formatCurrency,
          width: 150
        },
        {
          title: '套餐订购订单金额(元)',
          dataIndex: 'subscribed_fee',
          customRender: formatCurrency,
          width: 150
        },
        {
          slots: { title: 'stopped_fee_title' },
          dataIndex: 'stopped_fee',
          customRender: formatCurrency,
          width: 200
        },
        {
          slots: { title: 'card_used_fee_title' },
          dataIndex: 'card_used_fee',
          customRender: formatCurrency,
          width: 200
        },
        {
          title: '月订单结算金额(元)',
          dataIndex: 'total_fee',
          customRender: formatCurrency,
          width: 150
        },
        {
          title: '月订单结算佣金(元)',
          dataIndex: 'total_commission_fee',
          customRender: formatCurrency,
          width: 150
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findAgentMonthlyBills(Object.assign({ agent_id: this.agentId, booking_type: this.booking_type }, this.query)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          this.pagination = res.pagination
        }

        this.loading = false
      })
    },

    exportData() {
      exportAgentMonthlyBills(Object.assign({ agent_id: this.agentId, booking_type: this.booking_type }, this.query)).then((res) => {
        if (res.code === 0 && res.data.is_direct_download) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>

